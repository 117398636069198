import { SanityDocument, groq } from "next-sanity";
import { getClient } from "../utils";
import { IExperiment, IContent } from "../types";

export interface ISuppliers extends SanityDocument {
  type: "suppliers";
  content: IContent | IExperiment;
}

export const suppliersQuery = groq`*[_type == "suppliers"][0]{
...,
  content->{
  ...,
  _type == "content" => {
    ...,
  },
  _type == "experiment" => {
    ...,
    variants[]->
  }
}
}`;

export const getSuppliersData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ISuppliers>(suppliersQuery);
};
