import type { PortableTextBlock } from "@portabletext/types";
import useEmblaCarousel from "embla-carousel-react";
import AutoPlay from "embla-carousel-autoplay";
import { motion } from "framer-motion";
import { Children, useEffect, useState } from "react";
import clsx from "clsx";
import { Close } from "@chef/icons/small";
import { BlockContent } from "./BlockContent";

interface CarouselComponentProps {
  value?: {
    _key: string;
    autoplaySpeed: number;
    items: {
      _key: string;
      body: PortableTextBlock[];
    }[];
  };
}

interface Item {
  _key: string;
  body: PortableTextBlock[];
}

interface CarouselProps {
  name: string;
  children: React.ReactNode;
  autoplaySpeed?: number;
}

export const CarouselComponent = ({ value }: CarouselComponentProps) => {
  const [items, setItems] = useState<Item[]>([]);
  useEffect(() => {
    if (!value || !value.items) {
      return;
    }
    setItems(value.items);
  }, [value, value?.items]);

  if (!value || !value.items) {
    return null;
  }

  const handleRemoveItem = (itemKey: string) => {
    setItems((currentItems) =>
      currentItems.filter((item) => item._key !== itemKey),
    );
  };
  if (!items) {
    return null;
  }

  if (value.autoplaySpeed === 0) {
    return (
      <CarouselWithoutAutoplay
        name={value._key}
        autoplaySpeed={value.autoplaySpeed}
      >
        {items.map((item) => (
          <div
            className={clsx(
              items.length === 1 && "w-full",
              "w-11/12 relative lg:basis-1/3 shrink-0 embla__slide",
              items.length < 4 && "lg:shrink",
            )}
            key={item._key}
          >
            <BlockContent body={item.body} prose={{ padding: "none" }} />
            <button
              className="absolute flex items-center justify-center w-10 h-10 bg-transparent top-4 right-1"
              onClick={() => handleRemoveItem(item._key)}
            >
              <Close className="w-3 h-3" />
            </button>
          </div>
        ))}
      </CarouselWithoutAutoplay>
    );
  }

  return (
    <Carousel name={value._key} autoplaySpeed={value.autoplaySpeed}>
      {value.items.map((item) => (
        <div
          className="pl-4 embla__slide shrink-0 grow-0 basis-full"
          key={item._key}
        >
          <BlockContent body={item.body} />
        </div>
      ))}
    </Carousel>
  );
};

export const CarouselWithoutAutoplay = ({
  name,
  children,
  autoplaySpeed,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false,
    align: "start",
    dragFree: true,
  });

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    const onSelect = () => {
      setCurrentSlide(emblaApi.selectedScrollSnap());
    };
    emblaApi.on("select", onSelect);

    return () => {
      emblaApi.off("select", onSelect);
    };
  }, [emblaApi]);

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleGoToSlide = (slide: number) => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollTo(slide);
  };

  const nSlides = Children.count(children);

  return (
    <div id={`${name}-embla-carousel`} className="relative embla">
      <div className="relative flex gap-2 mb-2">
        {Array.from({ length: nSlides }).map((_, i) => (
          <motion.button key={i} onClick={() => handleGoToSlide(i)}>
            {currentSlide === i && (
              <div className="absolute inset-0 z-10">
                <motion.div
                  transition={{
                    duration: (autoplaySpeed ?? 0) / 1000,
                    ease: "linear",
                  }}
                  className="absolute inset-0 z-20 rounded-sm bg-primary"
                />
              </div>
            )}
          </motion.button>
        ))}
      </div>
      <div className="p-2 overflow-hidden embla__viewport" ref={emblaRef}>
        <div
          className={clsx(
            nSlides > 1 && "lg:gap-6 gap-2",
            "flex embla__container",
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export const Carousel = ({
  name,
  children,
  autoplaySpeed = 6000,
}: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      AutoPlay({
        delay: autoplaySpeed,
        stopOnInteraction: true,
      }),
    ],
  );

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    const onSelect = () => {
      setCurrentSlide(emblaApi.selectedScrollSnap());
    };

    const onAutoPlayStart = () => {
      setIsAutoPlaying(true);
    };

    const onAutoPlayStop = () => {
      setIsAutoPlaying(false);
    };

    emblaApi.on("select", onSelect);

    emblaApi.on("autoplay:play", onAutoPlayStart);
    emblaApi.on("autoplay:stop", onAutoPlayStop);

    return () => {
      emblaApi.off("select", onSelect);

      emblaApi.off("autoplay:play", onAutoPlayStart);
      emblaApi.off("autoplay:stop", onAutoPlayStop);
    };
  }, [emblaApi]);

  const handleGoToSlide = (slide: number) => {
    if (!emblaApi) {
      return;
    }

    emblaApi.plugins().autoplay?.stop();
    emblaApi.scrollTo(slide);
  };

  const nSlides = Children.count(children);

  return (
    <div id={`${name}-embla-carousel`} className="relative embla">
      <div className="relative flex gap-2 mb-2">
        {Array.from({ length: nSlides }).map((_, i) => (
          <motion.button
            key={i}
            className="relative h-2 rounded-sm bg-grey-2 grow"
            onClick={() => handleGoToSlide(i)}
          >
            {currentSlide === i && (
              <div className="absolute inset-0 z-10">
                <motion.div
                  key={`carousel-autoplaying-${isAutoPlaying}`}
                  animate={isAutoPlaying ? { width: "100%" } : undefined}
                  initial={isAutoPlaying ? { width: "0%" } : undefined}
                  transition={{
                    duration: (autoplaySpeed ?? 0) / 1000,
                    ease: "linear",
                  }}
                  className="absolute inset-0 z-20 rounded-sm bg-primary"
                />
              </div>
            )}
          </motion.button>
        ))}
      </div>
      <div className="overflow-hidden embla__viewport" ref={emblaRef}>
        <div className="flex -ml-4 embla__container">{children}</div>
      </div>
    </div>
  );
};
