import { groq, type SanityDocument } from "next-sanity";
import { getClient } from "../utils";
import { IExperiment, IContent } from "../types";

export interface ILanding extends SanityDocument {
  _type: "landing";
  content: IContent | IExperiment;
}

export const landingQuery = groq`*[_type == "landing"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    }
  }
}`;

export const getLandingData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ILanding>(landingQuery);
};
