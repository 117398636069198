import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";

import { getClient } from "../utils";
import { IExperiment, IContent } from "../types";

export interface IMealbox extends SanityDocument {
  _type: "mealbox";
  slug: Slug;
  content: IContent | IExperiment;
}

export const mealboxQuery = groq`*[_type == "mealbox" && slug.current == $slug][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    }
  }
}`;

export const mealboxesQuery = groq`*[_type == "mealbox"]`;

export const getMealboxData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IMealbox>(mealboxQuery, { slug });
};

export const getMealboxesData = async () => {
  const client = getClient();

  return client.fetch<IMealbox[]>(mealboxesQuery);
};
