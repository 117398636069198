import type { PortableTextBlock } from "@portabletext/types";
import clsx from "clsx";
import { ColorValue } from "@sanity/color-input";

import { Card } from "@chef/components";

import { Background } from "../types";
import { BlockContent } from "./BlockContent";
import { useBackground } from "../utils/useBackground";

interface ICard {
  _key: string;
  body: PortableTextBlock[];
  style: "default" | "borderless";
  backgroundColor: ColorValue;
}
interface CardsComponentProps {
  value: {
    items?: ICard[];
    background: Background;
  };
}

interface CardComponentProps {
  parentInverted: boolean;
  card: ICard;
}

const CardComponent = ({ card }: CardComponentProps) => {
  const { style } = useBackground({ color: card.backgroundColor });

  return (
    <Card
      key={card._key}
      className="rounded"
      style={style}
      noShadow={card.style === "borderless"}
      transparent
    >
      <BlockContent
        body={card.body}
        prose={{
          disabled: true,
        }}
      />
    </Card>
  );
};

export const CardsComponent = ({ value }: CardsComponentProps) => {
  const { className, invert, style } = useBackground(value.background);

  if (!value.items) {
    return null;
  }

  return (
    <div className={clsx("rounded", className)} style={style}>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4 page-max-width">
        {value.items.map((card) => (
          <CardComponent key={card._key} card={card} parentInverted={invert} />
        ))}
      </div>
    </div>
  );
};
