import type { PortableTextBlock } from "@portabletext/types";

import { Disclosure } from "@chef/components";

import { BlockContent } from "./BlockContent";
import { Background } from "../types";

interface IAccordion {
  _type: "accordion";
  items: {
    title: string;
    body: PortableTextBlock[];
  }[];
  background: Background;
}

interface AccordionComponentProps {
  value: IAccordion;
}

export const AccordionComponent = ({ value }: AccordionComponentProps) => {
  if (!value) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 page-max-width">
      {value.items?.map((e, i) => {
        return (
          <div key={i} className="first:border-none border-t-1.5 border-grey-1">
            <Disclosure
              className="my-4"
              Title={() => (
                <strong className="text-base text-left">{e.title}</strong>
              )}
            >
              <div className="mb-4">
                <BlockContent body={e.body} prose={{ padding: "none" }} />
              </div>
            </Disclosure>
          </div>
        );
      })}
    </div>
  );
};
