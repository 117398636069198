import { language } from "@chef/constants";

export const intl = (
  {
    no: {
      COMPLETED: "Fullført!",
      CURRENT: "Nåværende",
      POINTS_UNTIL_NEXT_LEVEL: (points: number) => `${points} poeng`,
    },
    se: {
      COMPLETED: "Avklarad!",
      CURRENT: "Nuvarande",
      POINTS_UNTIL_NEXT_LEVEL: (points: number) => `${points} poäng`,
    },
    dk: {
      COMPLETED: "Fuldført",
      CURRENT: "Nuværende",
      POINTS_UNTIL_NEXT_LEVEL: (points: number) => `${points} point`,
    },
  } as const
)[language];
