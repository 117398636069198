import Link from "next/link";
import type { PortableTextBlock } from "@portabletext/types";

import { ChevronRight } from "@chef/icons/small";

import { Background } from "../types";
import { useBackground } from "../utils/useBackground";
import { BlockContent } from "./BlockContent";

export interface ILinkWrapper {
  _type: "linkWrapper";
  body: PortableTextBlock[];
  href: string;
  _key: string;
  background: Background;
}

interface LinkWrapperComponentProps {
  value: ILinkWrapper;
}

const ExternalLinkWrapper = ({ value }: LinkWrapperComponentProps) => {
  return (
    <a
      href={value.href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <BlockContent body={value.body} prose={{ disabled: true }} />
    </a>
  );
};

const InternalLinkWrapper = ({ value }: LinkWrapperComponentProps) => {
  return (
    <Link href={value.href} style={{ textDecoration: "none" }}>
      <BlockContent body={value.body} prose={{ disabled: true }} />
    </Link>
  );
};

export const LinkWrapperComponent = ({ value }: LinkWrapperComponentProps) => {
  const { style } = useBackground(value.background);

  if (!value.href) {
    return null;
  }

  const external = value.href.startsWith("http");

  return (
    <div
      style={style}
      className="flex items-center justify-between h-full px-6 py-8 space-x-4"
    >
      {external ? (
        <ExternalLinkWrapper value={value} />
      ) : (
        <InternalLinkWrapper value={value} />
      )}
      <span>
        <ChevronRight />
      </span>
    </div>
  );
};
