import { SanityDocument, groq } from "next-sanity";
import type { PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface IOpeningHours extends SanityDocument {
  _type: "openingHours";
  body: PortableTextBlock;
}

type OpeningSlug = "chat_opening_hours" | "phone_opening_hours";

export const openingHoursQuery = groq`*[_type == "openingHours" && position->position.current == $slug][0]{
  ...,
  "position": { "current": position->position.current },
  body{
    ...,
  }
}
`;

export const getOpeningHoursData = async (
  slug: OpeningSlug,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IOpeningHours>(openingHoursQuery, { slug });
};
