import { useLiveQuery } from "next-sanity/preview";
import { useEffect, useState } from "react";

import {
  type IPromotion,
  promotionByCampaignIdQuery,
  promotionQuery,
  promotionsQuery,
} from "../queries";
import { getClient } from "../utils";

export const usePromotionData = (data: IPromotion | null) => {
  return useLiveQuery(data || null, promotionQuery, {
    slug: data?.slug.current,
  });
};

export const usePromotionDataByCampaignId = (
  dataOrCampaignId: IPromotion | string | null,
): [IPromotion | null, boolean] => {
  const [promotion, setPromotion] = useState<IPromotion | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  let campaignId: string | null = null;

  if (typeof dataOrCampaignId === "string") {
    campaignId = dataOrCampaignId;
  } else {
    campaignId = dataOrCampaignId?.campaignId || null;
  }

  const liveQueryResult = useLiveQuery(
    typeof campaignId === "string" ? null : campaignId,
    promotionByCampaignIdQuery,
    {
      campaignId,
    },
  );

  useEffect(() => {
    if (!campaignId) {
      setPromotion(null);
      setIsLoading(false);
      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const client = getClient();
        const data = await client.fetch<IPromotion | null>(
          promotionByCampaignIdQuery,
          { campaignId },
        );
        setPromotion(data);
      } catch (error) {
        console.error("An error occurred:", error);
        setPromotion(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (!liveQueryResult[0] && isLoading) {
      fetchData();
    }
  }, [campaignId, isLoading]);

  if (liveQueryResult[0]) {
    return [liveQueryResult[0], liveQueryResult[1]];
  } else {
    return [promotion, isLoading];
  }
};

export const usePromotionsData = (data?: IPromotion[]) => {
  return useLiveQuery(data || null, promotionsQuery);
};
