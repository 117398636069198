import { useLiveQuery } from "next-sanity/preview";
import {
  type ITellAFriendCompetition,
  type ITellAFriendDefault,
  type ITellAFriendLanding,
  tellAFriendCompetitionQuery,
  tellAFriendLandingQuery,
  tellAFriendQuery,
} from "../queries";

export const useTellAFriendData = (data?: ITellAFriendDefault) => {
  return useLiveQuery(data || null, tellAFriendQuery);
};
export const useTellAFriendCompetitionData = (
  data?: ITellAFriendCompetition,
) => {
  return useLiveQuery(data || null, tellAFriendCompetitionQuery);
};

export const useTellAFriendLandingData = (data?: ITellAFriendLanding) => {
  return useLiveQuery(data || null, tellAFriendLandingQuery);
};
