import clsx from "clsx";

import type { PortableTextBlock } from "@portabletext/types";

import { BlockContent } from "./BlockContent";
import { Background } from "../types";
import { useBackground } from "../utils/useBackground";

interface IItem {
  _key: string;
  _type: "item";
  title: string;
  sizing: number;
  columnStart: number;
  body: PortableTextBlock[];
}
interface GridItemProps {
  item: IItem;
}
interface GridProps {
  value: {
    _type: "grid";
    _key: string;
    title: string;
    sizing: number;
    background: Background;
    items: IItem[];
  };
}

const GridItemComponent = ({ item }: GridItemProps) => {
  return (
    <div key={item._key} className="h-full ">
      <BlockContent
        body={item.body}
        prose={{
          disabled: true,
          height: "full",
        }}
      />
    </div>
  );
};

export const GridComponent = ({ value }: GridProps) => {
  const { style, className } = useBackground(value.background);

  if (!value.items) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        "grid w-full grid-cols-4 gap-2 md:gap-4 print:gap-4 md:grid-cols-12 print:grid-cols-8 lg:gap-6",
      )}
      style={style}
    >
      {value.items.map((item, index) => {
        if (!item) {
          return null;
        }

        return (
          <div
            style={{
              gridColumn: `span ${item.sizing} / span ${item.sizing}`,
            }}
            key={index}
          >
            <GridItemComponent item={item} />
          </div>
        );
      })}
    </div>
  );
};
