import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";

import { getClient } from "../utils";
import { IExperiment, IContent, IPersonal } from "../types";

export interface ISection extends SanityDocument {
  _type: "section";
  position: Slug;
  pathConstraint?: string;
  content: IContent | IExperiment | IPersonal;
}

export const sectionQuery = groq`*[_type == "section" && position->position.current == $slug][0]{
  ...,
  "position": { "current": position->position.current },
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    },
    _type == "personal" => {
      ...,
      items[]{
        ...,
      conditions[]{
        ...,
        trait->
      },
      content->,
      }
    }
  }
}`;

export const sectionsQuery = groq`*[_type == "sections"]`;

export const getSectionData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ISection>(sectionQuery, { slug });
};

export const getSectionsData = async () => {
  const client = getClient();

  return client.fetch<ISection[]>(sectionsQuery);
};
