import { SanityReference } from "next-sanity";
import Image from "next/image";
import urlBuilder from "@sanity/image-url";
import Link from "next/link";

import { Card } from "@chef/components";

import { getClient } from "../utils";
import { useRelatedData } from "../hooks";
import { imageLoader } from "../helpers";

interface IRelated {
  _type: "related";
  relations: SanityReference[];
}

interface RelatedComponentProps {
  value: IRelated;
}

export const RelatedComponent = ({ value }: RelatedComponentProps) => {
  const client = getClient();
  const [data] = useRelatedData(
    value.relations?.map((r) => r._ref),
    "article", // TODO: Add support for other types than article -- how to know which type to use?
  );

  if (!value || !data) {
    return null;
  }

  return (
    <div className="grid grid-cols-3 gap-4 not-prose">
      {data.map((d) => {
        const imageUrl =
          d.seo.image && urlBuilder(client).width(720).image(d.seo.image).url();

        return (
          <Link href={`./${d.slug?.current}`}>
            <Card noPadding>
              {imageUrl && (
                <div className="relative w-full h-48">
                  <Image
                    loader={imageLoader}
                    src={imageUrl}
                    alt={d.seo.title}
                    className="object-cover rounded"
                    fill
                  />
                </div>
              )}
              <div className="p-4">
                <p>
                  <strong>{d.seo.title}</strong>
                </p>
                <p>{d.seo.description}</p>
              </div>
            </Card>
          </Link>
        );
      })}
    </div>
  );
};
