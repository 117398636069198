import { SanityDocument, groq } from "next-sanity";
import { type PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface ISharedMenu extends SanityDocument {
  _type: "sharedMenu";
  title: string;
  agreementId: string;
  topSection: PortableTextBlock[];
  bottomSection: PortableTextBlock[];
  showDefaultGreeting: boolean;
}

export const sharedMenuQuery = groq`*[_type == "sharedMenu" && agreementId == $agreementId][0]`;

export const sharedMenusQuery = groq`*[_type == "sharedMenu"]`;

export const getSharedMenuData = async (
  agreementId: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return await client.fetch<ISharedMenu>(sharedMenuQuery, {
    agreementId,
  });
};

export const getSharedMenusData = async (options: { preview?: boolean }) => {
  const client = getClient({ preview: options.preview });

  return await client.fetch<ISharedMenu[]>(sharedMenusQuery);
};
