export * from "./landing";
export * from "./article";
export * from "./campaign";
export * from "./cs";
export * from "./howItWorks";
export * from "./article";
export * from "./informationCookies";
export * from "./mealbox";
export * from "./openingHours";
export * from "./promotion";
export * from "./related";
export * from "./section";
export * from "./sustainability";
export * from "./tellAFriend";
export * from "./termsAndConditions";
export * from "./whereWeDeliver";
export * from "./sharedMenu";
export * from "./infoPage";
export * from "./recipesCategory";
export * from "./weekOptions";
export * from "./suppliers";
