import { SanityDocument, groq } from "next-sanity";
import type { PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface IWeekOption extends SanityDocument {
  _type: "weekOptions";
  showNudge?: boolean;
  displayRange: `${string}..${string}..${string}`;
  content?: PortableTextBlock[];
}

export const weekOptionsQuery = groq`*[_type == "weekOptions"]`;
export const getWeekOptionsData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IWeekOption[]>(weekOptionsQuery);
};
