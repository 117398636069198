import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";

import { getClient } from "../utils";
import { IContent } from "../types";

export interface IArticle extends SanityDocument {
  _type: "article";
  slug: Slug;
  content: IContent;
  relatedArticles?: IArticle[];
}

export const articleQuery = groq`*[_type == "article" && slug.current == $slug][0]{
  ...,

  relatedArticles[]->{
    ...,

    content->{
      _type,
      seo {
        image {
          asset->
        }
      }
    }
  },

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const articlesQuery = groq`*[_type == "article"] | order(_createdAt desc) {
  ...,

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const getArticleData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IArticle>(articleQuery, { slug });
};

export const getArticlesData = async () => {
  const client = getClient();

  return client.fetch<IArticle[]>(articlesQuery);
};
