import { default as ReactPlayer } from "react-player";

interface IVideo {
  title: string;
  url: string;
  autoplay: boolean;
  showControls: boolean;
  aspectRatio: string;
}

interface VideoComponentProps {
  value: IVideo;
}

export const VideoComponent = ({ value }: VideoComponentProps) => {
  if (!value) {
    return null;
  }

  return (
    <div
      className="relative w-full h-full max-h-screen aspect-video"
      style={{ aspectRatio: value.aspectRatio }}
    >
      <ReactPlayer
        url={value.url}
        playing={value.autoplay}
        controls={value.showControls}
        muted={value.autoplay}
        width="100%"
        height="100%"
        playsinline
      />
    </div>
  );
};
