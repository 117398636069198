import { useLiveQuery } from "next-sanity/preview";
import {
  ISharedMenu,
  sharedMenuQuery,
  sharedMenusQuery,
} from "../queries/sharedMenu";

export const useSharedMenuData = (data?: ISharedMenu | null) => {
  return useLiveQuery(data || null, sharedMenuQuery, {
    agreementId: data?.agreementId,
  });
};

export const useSharedMenusData = () => {
  return useLiveQuery(null, sharedMenusQuery);
};
