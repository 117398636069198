import { useLiveQuery } from "next-sanity/preview";
import { type ICampaign, campaignQuery, campaignsQuery } from "../queries";

export const useCampaignData = (data?: ICampaign) => {
  return useLiveQuery(data || null, campaignQuery, {
    slug: data?.slug.current,
  });
};

export const useCampaignsData = (data?: ICampaign[]) => {
  return useLiveQuery(data || null, campaignsQuery);
};
