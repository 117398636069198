import { groq } from "next-sanity";
import { Slug } from "sanity";

import { ISEO } from "../types";

export interface IRelated {
  slug: Slug;
  seo: ISEO;
}

export const relatedQuery = groq`
  *[_id in $ids]{
    seo,
    "slug": *[_type in ["experiment", $targetType] && references(^._id)]{
      _type == "article" => {
        slug
      }.slug,
      _type == "experiment" => {
        "slug": *[_type == $targetType && references(^._id)][0]{ slug }.slug
      }.slug
    }[current != null][0]
  }
`;
