import { SanityDocument, groq } from "next-sanity";
import { getClient } from "../utils";
import { IContent } from "../types";

export interface ITermsAndConditions extends SanityDocument {
  _type: "termsAndConditions";
  content: IContent;
}

export const termsAndConditionsQuery = groq`*[_type == "termsAndConditions"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
  }
}`;

export const getTermsAndConditionsData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ITermsAndConditions>(termsAndConditionsQuery);
};
