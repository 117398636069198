import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";
import { type PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface IRecipesCategory extends SanityDocument {
  _type: "recipesCategory";
  slug: Slug;
  title: string;
  topSection: PortableTextBlock[];
  bottomSection: PortableTextBlock[];
  categoryDetails: {
    description: string;
    image: {
      asset: {
        url: string;
      };
    };
  };
}

export const recipesCategoriesQuery = groq`*[_type == "recipesCategory"]`;

export const recipesCategoryQuery = groq`*[_type == "recipesCategory" && slug.current == $slug][0]`;

export const getRecipesCategoriesData = async (options: {
  preview?: boolean;
}) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IRecipesCategory[]>(recipesCategoriesQuery);
};

export const getRecipesCategoryData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IRecipesCategory>(recipesCategoryQuery, { slug });
};
