import { SanityDocument, groq } from "next-sanity";
import { getClient } from "../utils";
import { IExperiment, IContent } from "../types";

export interface IHowItWorks extends SanityDocument {
  _type: "howItWorks";
  content: IContent | IExperiment;
}

export const howItWorksQuery = groq`*[_type == "howItWorks"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    }
  }
}`;

export const getHowItWorksData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IHowItWorks>(howItWorksQuery);
};
