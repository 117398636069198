import type { PortableTextBlock } from "@portabletext/types";

import { Card } from "@chef/components";

import { BlockContent } from "./BlockContent";
import { useBackground } from "../utils/useBackground";
import { Background } from "../types";

export interface ICard {
  body: PortableTextBlock[];
  background: Background;
  noPadding: boolean;
  _type: "card";
  _key: string;
  style: "default" | "borderless";
}

interface CardComponentProps {
  value: ICard;
}

export const CardComponent = ({ value }: CardComponentProps) => {
  const { style } = useBackground(value.background);

  if (!value) {
    return null;
  }

  return (
    <Card
      key={value._key}
      className="h-full"
      style={style}
      noShadow={value.style === "borderless"}
      noPadding={value.noPadding}
      transparent
    >
      <BlockContent
        body={value.body}
        prose={{
          disabled: true,
        }}
      />
    </Card>
  );
};
