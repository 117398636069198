import urlBuilder from "@sanity/image-url";
import { SanityImageObjectStub, getImageDimensions } from "@sanity/asset-utils";
import Image from "next/image";
import clsx from "clsx";

import { useMediaQuery } from "@chef/hooks";

import { getClient } from "../utils";
import { imageLoader } from "../helpers";

type Sizing =
  | {
      sizing: "fixed";
      width: number;
      height: number;
    }
  | {
      sizing: "responsive";
      width?: never;
      height?: never;
    };

type MobileImage =
  | {
      useMobileImage: true;
      mobileImage: SanityImageObjectStub;
      breakpoint?: string;
    }
  | {
      useMobileImage: false;
      mobileImage?: never;
      breakpoint?: never;
    };

export type ImageComponentProps = {
  value?: {
    mainImage?: SanityImageObjectStub;
    altText?: string;
    caption?: string;
    align?: "left" | "center" | "right";
    fit?: "cover" | "contain" | "fill" | "none" | "scale-down";
    corners?: "sharp" | "rounded";
    sizes?: string;
  } & Sizing &
    MobileImage;
  sizes?: string;
  isInline?: boolean;
  className?: string;
};

export const ImageComponent = ({
  value,
  sizes,
  className,
}: ImageComponentProps) => {
  const [isMobile] = useMediaQuery(
    `(max-width: ${value?.breakpoint || "768px"})`,
  );

  if (!value) {
    return null;
  }

  const image =
    value.useMobileImage && isMobile ? value.mobileImage : value.mainImage;

  if (!image?.asset) {
    return null;
  }

  const client = getClient();

  const { width, height } = getImageDimensions(image);

  let _width, _height;

  if (value.fit === "fill") {
    _width = undefined;
    _height = undefined;
  } else if (value.sizing === "fixed") {
    _width = value.width;
    _height = value.height;
  } else {
    _width = width ?? 1;
    _height = height ?? 1;
  }

  const _image = urlBuilder(client)
    .image(image)
    .fit("max")
    .auto("format")
    .url();

  return (
    <figure className={className}>
      <div
        className={clsx(
          "flex flex-col",
          value.align === "center" && "items-center",
          value.align === "right" && "items-end",
          value.align === "left" && "items-start",
        )}
      >
        <Image
          loader={imageLoader}
          src={_image}
          alt={value.altText || "Image alt text"}
          width={_width}
          height={_height}
          fill={value.fit === "fill"}
          sizes={sizes || value.sizes}
          className={clsx("inline-block", {
            "object-contain": value.fit === "contain",
            "object-cover": value.fit === "cover" || value.fit === "fill",
            "object-none": value.fit === "none",
            "object-scale-down": value.fit === "scale-down",
            "!rounded-none": value.corners === "sharp",
          })}
          style={
            value.sizing === "fixed"
              ? {
                  width: _width,
                  height: _height,
                }
              : undefined
          }
        />

        {value.caption && <figcaption>{value.caption}</figcaption>}
      </div>
    </figure>
  );
};
