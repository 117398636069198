import { ColorValue } from "@sanity/color-input";
import clsx from "clsx";
import { CSSProperties } from "react";

import { luminosity } from "@chef/utils/color";

type UseBackground =
  | {
      color?: ColorValue;
      stretch?: boolean;
      shadow?: boolean;
      shadowSize?: "xl" | "sm";
      noPadding?: boolean;
    }
  | undefined;

export const useBackground = (args: UseBackground) => {
  if (!args) {
    return { className: "", style: {}, invert: false };
  }

  const { stretch, shadow, color, shadowSize, noPadding } = args;

  const className = clsx({
    "stretch-x page-padding": stretch === true,
    "px-4 xl:px-8 rounded": stretch === false,
    "shadow-xl": shadow === true && shadowSize === "xl",
    "shadow-sm": shadow === true && shadowSize === "sm",
    "!p-0": noPadding === true,
  });

  const style: CSSProperties = {};

  if (!color) {
    return {
      className,
      style,
      invert: false,
    };
  }

  const invert = luminosity(color.rgb) < 0.5;

  style.backgroundColor = color.hex;

  return {
    className,
    style,
    invert,
  };
};
