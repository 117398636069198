import { TableValue } from "@sanity/table";

interface TableComponentProps {
  value: TableValue;
}

export const TableComponent = ({ value }: TableComponentProps) => {
  return (
    <table>
      <tbody>
        {value.rows.map((row) => {
          return (
            <tr key={row._key}>
              {row.cells.map((cell) => {
                return <td key={cell}>{cell}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
