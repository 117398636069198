import clsx from "clsx";

import { SawContainer } from "@chef/components";

import { BlockContent, BlockContentProps } from "./BlockContent";

type Color = keyof typeof colorMapping;

interface IStep {
  _type: "step";
  background: Color;
  indexColor: Color;
  items: {
    title: string;
    body: BlockContentProps["body"];
  }[];
  hasSaw?: boolean;
}

interface StepProps {
  value: IStep;
}

const colorMapping = {
  default: "text-grey-3",
  white: "text-white",
  secondary: "text-secondary",
  primary: "text-primary",
  highlight: "text-highlight",
  error: "text-error",
};

export const Step = ({
  value: { items, background, indexColor, hasSaw },
}: StepProps) => {
  return (
    <div className="flex flex-col gap-4">
      {items?.map((item, i) => {
        return (
          <div className={clsx(hasSaw && "items-center", "flex gap-4")} key={i}>
            {hasSaw ? (
              <div>
                <SawContainer
                  className={clsx(colorMapping[background], "w-10 h-10")}
                >
                  <strong className={clsx(colorMapping[indexColor])}>
                    {i + 1}
                  </strong>
                </SawContainer>
              </div>
            ) : (
              `${i + 1}.`
            )}
            <BlockContent body={item.body} prose={{ padding: "none" }} />
          </div>
        );
      })}
    </div>
  );
};
