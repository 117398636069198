import { useLiveQuery } from "next-sanity/preview";
import { type IArticle, articleQuery, articlesQuery } from "../queries";

export const useArticleData = (data?: IArticle) => {
  return useLiveQuery(data || null, articleQuery, { slug: data?.slug.current });
};

export const useArticlesData = (data?: IArticle[]) => {
  return useLiveQuery(data || null, articlesQuery);
};
