import { DeliveryChecker } from "@chef/smart-components";

interface DeliveryCheckerProps {
  value?: {
    title?: string;
    subtitle?: string;
    showContactForm: boolean;
    showCTA: boolean;
  };
}

export const DeliveryCheckerComponent = ({ value }: DeliveryCheckerProps) => {
  if (!value) {
    return null;
  }
  const { title, subtitle, showContactForm, showCTA } = value;
  return (
    <div>
      {title && <h1 className="mb-6">{title}</h1>}
      {subtitle && <p className="mb-4">{subtitle}</p>}
      <DeliveryChecker showContactForm={showContactForm} showCTA={showCTA} />
    </div>
  );
};
