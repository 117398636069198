import { Tag } from "@chef/components";

export interface ITag {
  _type: "tag";
  text: string;
  background: "default" | "secondary" | "highlight" | "white" | "error";
  size: "default" | "small";
  casing: "uppercase" | "capitalize" | "normal-case" | "lowercase";
}

interface TagProps {
  value: ITag;
}

export const TagComponent = ({
  value: { text, background, size, casing },
}: TagProps) => {
  if (!text) {
    return null;
  }

  const tagProps = {
    [background]: true,
    small: size === "small",
    casing,
  };
  return <Tag {...tagProps}>{text}</Tag>;
};
