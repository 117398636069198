import type { PortableTextBlock } from "@portabletext/types";
import clsx from "clsx";

import { BlockContent } from "./BlockContent";
import { Background } from "../types";
import { useBackground } from "../utils/useBackground";

interface IContent {
  leftBlock: PortableTextBlock[];
  rightBlock: PortableTextBlock[];
}

interface IInformationCard {
  title: string;
  body: PortableTextBlock[];
  background: Background;
  content: IContent;
  noPadding: boolean;
  responsive: boolean;
}

interface InformationCardComponentProps {
  value: IInformationCard;
}

export const InformationCardComponent = ({
  value,
}: InformationCardComponentProps) => {
  const { style, className } = useBackground(value.background);

  if (!value?.content) {
    return null;
  }

  return (
    <div
      style={style}
      className={clsx(
        className,
        value.noPadding ? "!p-0" : "!p-4",
        value.responsive && "md:flex-col md:text-center",
        "flex items-center gap-4",
      )}
    >
      <div className="flex-shrink-0">
        <BlockContent
          body={value.content.leftBlock}
          prose={{ disabled: true }}
        />
      </div>

      <BlockContent
        body={value.content.rightBlock}
        prose={{ disabled: true }}
      />
    </div>
  );
};
