import { SanityDocument, groq } from "next-sanity";
import { getClient } from "../utils";
import { IContent } from "../types";

export interface IPrivacyPolicy extends SanityDocument {
  _type: "privacyPolicy";
  content: IContent;
}

export const privacyPolicyQuery = groq`*[_type == "privacyPolicy"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
  }
}`;

export const getPrivacyPolicyData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IPrivacyPolicy>(privacyPolicyQuery);
};
