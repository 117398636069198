import clsx from "clsx";
import urlBuilder from "@sanity/image-url";
import { PortableTextTypeComponentProps } from "@portabletext/react";
import { CSSProperties } from "react";

import { useMediaQuery } from "@chef/hooks";
import { BASE_64_1X1_GREY } from "@chef/constants";

import { BlockContent } from "./BlockContent";
import { getClient } from "../utils";
import { IHero } from "../types";

interface HeroProps extends PortableTextTypeComponentProps<IHero> {
  className?: string;
}

export const HeroComponent = ({ value, className }: HeroProps) => {
  const [isMobile] = useMediaQuery(
    `(max-width: ${value.mobileImage?.breakpoint})`,
  );

  const client = getClient();

  if (!value.image.image) {
    return null;
  }

  const image =
    value.useMobileImage && isMobile
      ? value.mobileImage.image
      : value.image.image;

  const imageUrl = image
    ? urlBuilder(client).image(image).fit("max").format("webp").url()
    : BASE_64_1X1_GREY;

  let backgroundPositionX: CSSProperties["backgroundPositionX"] = "unset";
  let backgroundPositionY: CSSProperties["backgroundPositionY"] = "unset";

  if (image.hotspot) {
    backgroundPositionX = `${Math.round(image.hotspot.x * 100)}%`;
    backgroundPositionY = `${Math.round(image.hotspot.y * 100)}%`;
  }

  return (
    <div
      className={clsx(
        "w-screen stretch-x page-padding pb-10 mb-4 md:mb-8 min-h-[50vh] lg:min-h-[33vw] flex flex-col !-mt-4 xl:!-mt-8",
        {
          "justify-start": value.verticalAlignment === "top",
          "justify-center": value.verticalAlignment === "center",
          "justify-end": value.verticalAlignment === "bottom",
        },
        className,
      )}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX,
        backgroundPositionY,
      }}
    >
      {value.body && (
        <div className="relative w-full page-max-width">
          <div
            className={clsx(
              !value.body && "hidden",
              !value.centered && "md:text-left md:w-1/3 lg:w-2/5",
              "text-center",
            )}
          >
            <BlockContent body={value.body} prose={{ padding: "none" }} />
          </div>
        </div>
      )}
    </div>
  );
};
