import { SanityDocument, groq } from "next-sanity";

import { getClient } from "../utils";
import { IContent } from "../types";

export interface ICS extends SanityDocument {
  _type: "cs";
  content: IContent;
}

export const csQuery = groq`*[_type == "cs"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
      body[]{
        ...,
        _type == "reference" => @->body{
          ...,
        },
        _type == "richBlock" => {
          ...,
          body[]{
            ...,
            _type == "reference" => @->body{
              ...,
            }
          }
        }
      }
    },
  }
}
`;

export const getCSData = async (_: null, options: { preview?: boolean }) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ICS>(csQuery);
};
