import type { PortableTextBlock } from "@portabletext/types";
import clsx from "clsx";

import { BlockContent } from "./BlockContent";
import { useBackground } from "../utils/useBackground";
import { Background } from "../types";

interface ColumnsProps {
  value: {
    sizing: number;
    gap?: number;
    padding: "none" | "vertical";
    verticalAlignment: "top" | "center" | "bottom";
    leftBlock: PortableTextBlock[];
    rightBlock: PortableTextBlock[];
    background: Background;
  };
}

export const ColumnsComponent = ({ value }: ColumnsProps) => {
  const { className, invert, style } = useBackground(value.background);

  const gap = value.gap ?? 1;
  const sizeA = value.sizing || 0;
  const sizeB = 12 - sizeA - gap;

  return (
    <div className={className} style={style}>
      <div
        className={clsx(
          "grid grid-cols-1 md:grid-cols-12 md:gap-4 xl:gap-6 page-max-width",
          {
            "items-start": value.verticalAlignment === "top",
            "items-center": value.verticalAlignment === "center",
            "items-end": value.verticalAlignment === "bottom",
          },
        )}
      >
        <div
          className={clsx(
            "mb-4 md:mb-0",
            value.padding === "vertical" && "pt-4 md:py-4 xl:py-8",
          )}
          style={{ gridColumn: `span ${sizeA} / span ${sizeA}` }}
        >
          <BlockContent
            body={value.leftBlock}
            prose={{ maxWidth: "none", padding: "none", invert }}
          />
        </div>

        {gap > 0 && (
          <div
            aria-hidden="true"
            style={{ gridColumn: `span ${gap} / span ${gap}` }}
            className="hidden md:block"
          />
        )}

        <div
          className={clsx(
            value.padding === "vertical" && "pb-4 md:py-4 xl:py-8",
          )}
          style={{
            gridColumn: `span ${sizeB} / span ${sizeB}`,
          }}
        >
          <BlockContent
            body={value.rightBlock}
            prose={{ maxWidth: "none", padding: "none", invert }}
          />
        </div>
      </div>
    </div>
  );
};
