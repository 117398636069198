import { useEffect, useRef } from "react";
import Script from "next/script";

import { TRUSTPILOT } from "@chef/constants";

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (element: HTMLElement | null, flag?: boolean) => void;
    };
  }
}

export const TrustBoxComponent = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <>
      <Script>
        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        />
      </Script>
      <div
        ref={ref}
        className="py-4 trustpilot-widget md:py-8"
        data-locale={TRUSTPILOT.LOCALE}
        data-template-id={TRUSTPILOT.TEMPLATE_ID}
        data-businessunit-id={TRUSTPILOT.BUSINESS_UNIT_ID}
        data-style-height="150px"
        data-style-width="100%"
        data-theme="light"
      >
        <a
          href="https://no.trustpilot.com/review/adamsmatkasse.no"
          target="_blank"
          rel="noopener noreferrer"
          className="hidden"
        >
          Trustpilot
        </a>
      </div>
    </>
  );
};
