import clsx from "clsx";
import urlBuilder from "@sanity/image-url";
import { CSSProperties } from "react";
import { SanityDocument } from "next-sanity";
import { SanityImageObjectStub } from "@sanity/asset-utils";
import type { PortableTextBlock } from "@portabletext/types";
import { PortableTextTypeComponentProps } from "@portabletext/react";

import { useMediaQuery } from "@chef/hooks";
import { BASE_64_1X1_GREY } from "@chef/constants";

import { BlockContent } from "./BlockContent";
import { getClient } from "../utils";

interface CenteredHeroProps extends SanityDocument {
  _type: "centeredHero";
  image: {
    image?: SanityImageObjectStub;
  };
  verticalAlignmentDesktop: "top" | "center" | "bottom";
  verticalAlignmentTablet: "top" | "center" | "bottom";
  verticalAlignmentMobile: "top" | "center" | "bottom";
  stretched: boolean;
  body?: PortableTextBlock[];
}

export const CenteredHeroComponent = ({
  value,
}: PortableTextTypeComponentProps<CenteredHeroProps>) => {
  const [isMobile] = useMediaQuery(
    `(max-width: ${value.mobileImage.breakpoint})`,
  );

  const client = getClient();

  if (!value.body || !value.image.image) {
    return null;
  }

  const image =
    value.useMobileImage && isMobile
      ? value.mobileImage.image
      : value.image.image;

  const imageUrl = image
    ? urlBuilder(client).image(image).fit("max").url()
    : BASE_64_1X1_GREY;

  let backgroundPositionX: CSSProperties["backgroundPositionX"] = "unset";
  let backgroundPositionY: CSSProperties["backgroundPositionY"] = "unset";

  if (image.hotspot) {
    backgroundPositionX = `${Math.round(image.hotspot.x * 100)}%`;
    backgroundPositionY = `${Math.round(image.hotspot.y * 100)}%`;
  }

  // Mobile Alignment
  const isTopAlignedMobile = value.verticalAlignmentMobile === "top";
  const isCenterAlignedMobile = value.verticalAlignmentMobile === "center";
  const isBottomAlignedMobile = value.verticalAlignmentMobile === "bottom";
  // Tablet Alignment
  const isTopAlignedTablet = value.verticalAlignmentTablet === "top";
  const isCenterAlignedTablet = value.verticalAlignmentTablet === "center";
  const isBottomAlignedTablet = value.verticalAlignmentTablet === "bottom";
  // Desktop Alignment
  const isTopAlignedDesktop = value.verticalAlignmentDesktop === "top";
  const isCenterAlignedDesktop = value.verticalAlignmentDesktop === "center";
  const isBottomAlignedDesktop = value.verticalAlignmentDesktop === "bottom";

  return (
    <div
      className={clsx(
        " w-screen stretch-x  page-padding pb-10 mb-4 md:mb-8 min-h-[50vh] flex flex-col !-mt-4 xl:!-mt-8",
        // Mobile Alignment
        isTopAlignedMobile && "justify-start",
        isCenterAlignedMobile && "justify-center",
        isBottomAlignedMobile && "justify-end",
        // Tablet Alignment
        isTopAlignedTablet && "md:justify-start",
        isCenterAlignedTablet && "md:justify-center",
        isBottomAlignedTablet && "md:justify-end",
        // Desktop Alignment
        isTopAlignedDesktop && "lg:justify-start",
        isCenterAlignedDesktop && "lg:justify-center",
        isBottomAlignedDesktop && "lg:justify-end",
      )}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX,
        backgroundPositionY,
      }}
    >
      <div
        className={clsx(
          !value.body && "hidden",
          !value.stretched && "md:text-left md:w-2/3 lg:w-1/2",
          "text-center mx-auto",
          // Mobile Alignment
          isTopAlignedMobile && "-translate-y-1/2",
          isCenterAlignedMobile && "translate-y-0",
          isBottomAlignedMobile && "translate-y-1/3",
          // Tablet Alignment
          isTopAlignedTablet && "md:-translate-y-1/2",
          isCenterAlignedTablet && "md:translate-y-0",
          isBottomAlignedTablet && "md:translate-y-1/3",
          // Desktop Alignment
          isTopAlignedDesktop && "lg:-translate-y-1/2",
          isCenterAlignedDesktop && "lg:translate-y-0",
          isBottomAlignedDesktop && "lg:translate-y-1/3",
        )}
      >
        <BlockContent body={value.body} prose={{ padding: "none" }} />
      </div>
    </div>
  );
};
