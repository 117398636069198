import { SanityDocument, groq } from "next-sanity";
import { PortableTextBlock, Slug } from "sanity";

import { getClient } from "../utils";
import { BlockContentProps } from "../components";
import { IContent, IExperiment } from "../types";

export interface ITellAFriendDefault extends SanityDocument {
  _type: "tellAFriendDefault";
  title: string;
  tellAFriendDefaultBlock: PortableTextBlock[];
}
export interface ITellAFriendCompetition extends SanityDocument {
  _type: "tellAFriendCompetition";
  competitionBlock: BlockContentProps["body"];
  competitionSetting: {
    competitionRequirements: {
      invitesRequired: number;
      deliveriesRequired: number;
    };

    competitionStatusText: BlockContentProps["body"];
    deliveriesRequiredText: BlockContentProps["body"];

    dateSetting: {
      endDate: string;
      deliveryCutoffDate: string;
      startDate: string;
    };

    prizeValue: number;
    title: string;
  };
  howCompetitionWorks: BlockContentProps["body"];
  competitionPrize: BlockContentProps["body"];
}

export interface ITellAFriendLanding extends SanityDocument {
  _type: "campaign";
  slug: Slug;
  content: IContent | IExperiment;
}

export const tellAFriendQuery = groq`*[_type == 'tellAFriendDefault']| order(_updatedAt desc)[0]`;
export const tellAFriendCompetitionQuery = groq`*[_type == 'tellAFriendCompetition']| order(_updatedAt desc)[0]`;

export const tellAFriendSmsQuery = groq`*[_type == 'tellAFriendDefault'][0]["smsMessage"]`;

export const tellAFriendLandingQuery = groq`*[_type == "tellAFriendLanding"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    }
  }
}`;

export const getTellAFriendData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ITellAFriendDefault | null>(tellAFriendQuery);
};
export const getTellAFriendCompetitionData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ITellAFriendCompetition | null>(
    tellAFriendCompetitionQuery,
  );
};

export const getTellAFriendSmsTemplate = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch(tellAFriendSmsQuery);
};

export const getTellAFriendLandingData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ITellAFriendLanding>(tellAFriendLandingQuery);
};
