/**
 * https://stackoverflow.com/a/3943023
 *
 * Rewritten with Github Copilot
 */
export const luminosity = (rgb: { r: number; g: number; b: number }) => {
  const r = rgb.r / 255.0;
  const g = rgb.g / 255.0;
  const b = rgb.b / 255.0;

  const cR = r <= 0.04045 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const cG = g <= 0.04045 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const cB = b <= 0.04045 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  const L = 0.2126 * cR + 0.7152 * cG + 0.0722 * cB;

  return L;
};
