import { SanityDocument, groq } from "next-sanity";
import { Slug } from "sanity";
import { IContent } from "../types";
import { getClient } from "../utils";

export interface IInfoPage extends SanityDocument {
  _type: "article";
  slug: Slug;
  content: IContent;
}

export const infoPageQuery = groq`*[_type == "infoPage" && slug.current == $slug][0]{
  ...,

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const infoPagesQuery = groq`*[_type == "infoPage"]{
  ...,

  content->{
    ...,
    seo {
      ...,

      image {
        asset->
      }
    }
  }
}`;

export const getInfoPageData = async (
  slug: string,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<IInfoPage>(infoPageQuery, { slug });
};

export const getInfoPagesData = async () => {
  const client = getClient();

  return client.fetch<IInfoPage[]>(infoPagesQuery);
};
