import type { PortableTextBlock } from "@portabletext/types";
import { useState } from "react";

import { BlockContent } from "./BlockContent";
import { Background } from "../types";

interface IDisclosure {
  _type: "disclosure";
  items: {
    title: string;
    body: PortableTextBlock[];
  }[];
  background: Background;
  title: string;
}

interface DisclosureComponentProps {
  value: IDisclosure;
}

export const DisclosureComponent = ({ value }: DisclosureComponentProps) => {
  const [expanded, setExpanded] = useState(false);

  if (!value) {
    return null;
  }

  const numberOfItems = value.items.length;
  const expandCount = numberOfItems > 0 ? `(${numberOfItems})` : "";

  if (!expanded) {
    return (
      <button
        className="underline text-primary"
        onClick={() => setExpanded(true)}
      >
        {value.title} {expandCount}
      </button>
    );
  }

  return (
    <div className="flex flex-col gap-4 page-max-width">
      {value.items?.map((e, i) => {
        return (
          <div key={i} className="mb-4">
            <BlockContent body={e.body} prose={{ padding: "none" }} />
          </div>
        );
      })}
    </div>
  );
};
