import clsx from "clsx";

interface ProseProps {
  maxWidth?: "default" | "none" | "medium";
  padding?: "around" | "vertical" | "horizontal" | "padding-left" | "none";
  invert?: boolean;
  height?: "default" | "full";
  children: React.ReactNode;
}

export const Prose = ({
  maxWidth = "default",
  padding = "vertical",
  height = "default",
  invert = false,
  children,
}: ProseProps) => {
  return (
    <article
      className={clsx(
        "prose prose-sm md:prose-base prose-img:rounded",
        maxWidth === "none" ? "max-w-none" : "mx-auto",
        maxWidth === "medium" && "max-w-prose-md",
        {
          "py-4 xl:py-8 page-padding": padding === "around",
          "py-4 xl:py-8": padding === "vertical",
          "page-padding": padding === "horizontal",
          "pl-4 xl:pl-8": padding === "padding-left",
        },
        invert && "prose-invert",
        height === "full" && "h-full",
      )}
    >
      {children}
    </article>
  );
};
