import type { PortableTextBlock } from "@portabletext/types";
import clsx from "clsx";

import { BlockContent } from "./BlockContent";
import { useBackground } from "../utils/useBackground";
import { Background } from "../types";

interface BlockProps {
  value: {
    sizing: number;
    body: PortableTextBlock[];
    background: Background;
    padding: "none" | "vertical";
  };
}

export const BlockComponent = ({ value }: BlockProps) => {
  const { className, invert, style } = useBackground(value.background);

  const gutters = (12 - value.sizing) / 2;

  return (
    <div className={className} style={style}>
      <div
        className={clsx(
          "grid grid-cols-1 md:grid-cols-12 md:gap-4 xl:gap-6 page-max-width",
          value.padding !== "none" && "py-4 xl:py-8",
        )}
      >
        {gutters !== 0 && (
          <div
            aria-hidden="true"
            style={{ gridColumn: `span ${gutters} / span ${gutters}` }}
          />
        )}

        <div
          style={{
            gridColumn: `span ${value.sizing} / span ${value.sizing}`,
          }}
        >
          <BlockContent
            body={value.body}
            prose={{ maxWidth: "none", padding: "none", invert }}
          />
        </div>

        {gutters !== 0 && (
          <div
            aria-hidden="true"
            style={{ gridColumn: `span ${gutters} / span ${gutters}` }}
          />
        )}
      </div>
    </div>
  );
};
