import { groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";

import { getClient } from "../utils";
import { ImageComponentProps } from "../components";

export interface ILoyaltyNavigation {
  title: string;
  menuItems: {
    label: string;
    href: string;
    icon: ImageComponentProps["value"];
  }[];
}

export const loyaltyNavigationQuery = groq`*[_type == 'loyaltyNavigation'][0]`;

export const useLoyaltyNavigationData = (data?: ILoyaltyNavigation) => {
  return useLiveQuery(data || null, loyaltyNavigationQuery);
};

export const getLoyaltyNavigationData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ILoyaltyNavigation>(loyaltyNavigationQuery);
};
