import clsx from "clsx";

interface SpacerComponentProps {
  value?: {
    height: string;
    divider: boolean;
  };
}

export const SpacerComponent = ({ value }: SpacerComponentProps) => {
  if (!value) {
    return null;
  }

  return (
    <>
      <div
        className={clsx(value.divider && "border-b-2")}
        style={{
          height: `${+value.height / 8}rem`,
        }}
        aria-hidden="true"
      />
      <div
        style={{
          height: `${+value.height / 8}rem`,
        }}
        aria-hidden="true"
      />
    </>
  );
};
