import { SanityDocument, groq } from "next-sanity";
import { getClient } from "../utils";
import { IExperiment, IContent } from "../types";

export interface ISustainability extends SanityDocument {
  _type: "sustainability";
  content: IContent | IExperiment;
}

export const sustainabilityQuery = groq`*[_type == "sustainability"][0]{
  ...,
  content->{
    ...,
    _type == "content" => {
      ...,
    },
    _type == "experiment" => {
      ...,
      variants[]->
    }
  }
}`;

export const getSustainabilityData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ISustainability>(sustainabilityQuery);
};
