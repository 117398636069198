import { useLiveQuery } from "next-sanity/preview";
import { useEffect, useState } from "react";

import {
  type ISection,
  sectionQuery,
  sectionsQuery,
  getSectionData,
} from "../queries";

export const useSectionData = (data?: ISection | null) => {
  return useLiveQuery(data || null, sectionQuery, {
    slug: data?.position.current,
  });
};

export const useSectionDataBySlug = (slug: string) => {
  const [section, setSection] = useState<null | ISection>(null);

  const [liveQueryResult] = useLiveQuery<ISection | null>(null, sectionQuery, {
    slug,
  });

  useEffect(() => {
    (async () => {
      const data = await getSectionData(slug, { preview: false });
      setSection(data);
    })();
  }, [slug]);

  if (liveQueryResult) {
    return [liveQueryResult, false] as const;
  } else {
    return [section, false] as const;
  }
};

export const useSectionsData = (data?: ISection[]) => {
  return useLiveQuery(data || null, sectionsQuery);
};
