import type { PortableTextBlock } from "@portabletext/types";
import clsx from "clsx";

import { Table } from "@chef/components";
import { createBenefits, useLoyalty } from "@chef/feature-flags";
import { getTierLevel } from "@chef/state-management/helpers";

import { LoyaltyTierIcon } from "@chef/smart-components";
import { Check } from "@chef/icons/small";

import { BlockContent } from "./BlockContent";
import { useBackground } from "../utils/useBackground";
import { Background } from "../types";

import { ImageComponent, ImageComponentProps } from "./Image";
import { intl } from "./LoyaltyTable.Intl";

interface ILoyaltyTable {
  striped?: boolean;
  bordered?: boolean;
  header: PortableTextBlock[];
  title: string;
  image: ImageComponentProps["value"];
  content: {
    benefit: string;
    description: PortableTextBlock[];
    _key: string;
  }[];
  background: Background;
}

interface LoyaltyTableComponentProps {
  value: ILoyaltyTable;
}

export const LoyaltyTableComponent = ({
  value,
}: LoyaltyTableComponentProps) => {
  const { style } = useBackground(value.background);
  const { data: loyaltyData } = useLoyalty();

  if (!loyaltyData) {
    return null;
  }

  const loyalty = {
    tierId: loyaltyData.currentLevel,
    currentTier: loyaltyData.currentLevel,
    currentLevel: loyaltyData.currentLevel,
    pointsUntilNextLevel: loyaltyData.accruedPointsToNextLevel,
  };

  const progress = loyaltyData.progress;

  const { currentLevel } = loyalty;

  const badgeIcons = loyaltyData.levels.map((l) => {
    const tierId = l.level;

    const { current, complete } = getTierLevel(tierId, currentLevel);

    const tierProgress = (complete && {
      current: 100,
      projected: 100,
    }) ||
      (current && progress) || { current: 0, projected: 0 };

    return {
      tierId,
      Icon: <LoyaltyTierIcon tierId={tierId} progress={tierProgress} />,
    };
  });

  const columnHeader = loyaltyData.levels.map((data, i) => {
    const tierId = i + 1;
    const { complete, current, hasTierAchieved } = getTierLevel(
      tierId,
      currentLevel,
    );

    const badgeIcon = badgeIcons.find((Icon) => Icon.tierId === i + 1);

    const levelStatusLabel =
      (current && intl.CURRENT) ||
      (complete && intl.COMPLETED) ||
      (!!data.requirement && intl.POINTS_UNTIL_NEXT_LEVEL(data.requirement));

    return (
      <th className="flex items-center justify-center" key={i}>
        {badgeIcon && (
          <div className="flex items-center justify-center w-10 h-10 mx-auto">
            {badgeIcon.Icon}
          </div>
        )}
        <div className="text-xs">{data.name}</div>
        <div
          className={clsx(
            hasTierAchieved && "text-secondary",
            "text-xxs font-light",
          )}
        >
          {levelStatusLabel}
        </div>
      </th>
    );
  });

  interface Reward {
    description?: string;
    [key: string]: any;
  }

  const addBenefitDescription = (
    benefits: ReturnType<typeof createBenefits>[number]["benefits"],
  ) => {
    return benefits.map((benefit) => {
      const description = value.content.find(
        (loyaltyBenefit: ILoyaltyTable["content"][number]) =>
          benefit.name === loyaltyBenefit.benefit,
      )?.description;

      if (!description) {
        return null;
      }

      return {
        ...benefit,
        description: (
          <BlockContent
            body={description}
            prose={{ padding: "padding-left" }}
          />
        ),
      };
    });
  };

  const loyaltyBenefitsByReward: Reward = [
    ...Array(loyaltyData.levels[0].benefits.length),
  ].map((_, i) => {
    // Return an object that represents the row
    return loyaltyData.levels.map((tier) => {
      const { benefits } = tier;

      return {
        ...addBenefitDescription(benefits)[i],
      };
    });
  });

  const createRowContent = (
    reward: (typeof loyaltyBenefitsByReward)[number][number],
    index: number,
  ) => {
    const { value } = reward;

    const { hasTierAchieved } = getTierLevel(index + 1, currentLevel);

    if (typeof value === "boolean") {
      return value ? (
        <div
          className={clsx(
            hasTierAchieved ? "bg-secondary" : "bg-secondary/50",
            "flex items-center justify-center rounded-full w-5 h-5",
          )}
        >
          <Check className="font-semibold text-white" width={10} height={10} />
        </div>
      ) : null;
    }
    return (
      <div
        className={clsx(
          !hasTierAchieved && "text-black/50",
          "text-xs md:text-sm",
        )}
      >
        <strong>{value}p</strong>
      </div>
    );
  };

  return (
    <div
      style={style}
      className="grid col-span-full lg:col-span-5 xl:col-span-9 lg:col-start-4 "
    >
      <div className="shadow-xl xl:grid col-span-full xl:grid-cols-10">
        <div className="relative xl:hidden h-60 md:h-80">
          <ImageComponent value={value.image} sizes="50vw" />
        </div>

        <Table
          bordered={value.bordered}
          striped={value.striped}
          className="m-0 bg-white xl:col-span-7 xl:col-start-1"
          title={
            <BlockContent body={value.header} prose={{ padding: "none" }} />
          }
        >
          <>
            <thead className="text-xs">{columnHeader}</thead>

            {loyaltyBenefitsByReward.map((rewards: Reward, index: number) => {
              if (!rewards[0].description) {
                return null;
              }
              return (
                <Table.Tr header={rewards[0].description} key={index}>
                  {rewards.map(createRowContent)}
                </Table.Tr>
              );
            })}
          </>
        </Table>

        <div className="relative hidden col-span-3 col-start-8 xl:inline-block">
          <ImageComponent value={value.image} sizes="25vw" />
        </div>
      </div>
    </div>
  );
};
