import { SanityDocument, groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";
import type { PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

interface ILoyaltyQandA {
  id: string;
  body: PortableTextBlock[];
}

export interface ILoyaltyIntroduction extends SanityDocument {
  _type: "loyaltyIntroduction";
  title: string;
  body: PortableTextBlock[];
  qAndA: ILoyaltyQandA;
}

export const loyaltyIntroductionQuery = groq`*[_type == "loyaltyIntroduction"][0]`;

export const useLoyaltyIntroductionData = (data?: ILoyaltyIntroduction) => {
  return useLiveQuery(data || null, loyaltyIntroductionQuery);
};

export const getLoyaltyIntroductionData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ILoyaltyIntroduction>(loyaltyIntroductionQuery);
};
