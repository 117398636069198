import clsx from "clsx";

import { Container, Skeleton, TrustPilotCarousel } from "@chef/components";
import { useGetTrustpilotReviewsQuery } from "@chef/state-management";
import { language } from "@chef/constants";

import { Background } from "../types";
import { useBackground } from "../utils/useBackground";

interface ITrustPilot {
  _type: "trustpilot";
  title: string;
  stars: number;
  background: Background;
}

interface TrustpilotComponentProps {
  value?: ITrustPilot;
}

export const TrustPilotComponent = ({ value }: TrustpilotComponentProps) => {
  const { className, style } = useBackground(value?.background);

  const { data, isLoading } = useGetTrustpilotReviewsQuery(
    { stars: value?.stars || 5 },
    { skip: !value },
  );

  if (!value || !data || isLoading) {
    return (
      <Container grid outerClassName="py-10 md:py-20">
        <div className="p-10 col-span-full md:col-span-4">
          <div className="relative flex flex-col h-full">
            <Skeleton width={40} height={9} />
            <Skeleton width={52} height={9} className="mt-6" />
            <div className="absolute hidden h-full space-x-2 md:flex grow-1 top-3/4">
              <Skeleton width={10} height={10} />
              <Skeleton width={10} height={10} />
            </div>
          </div>
        </div>
        {[...Array(2)].map((_, i) => (
          <div
            key={`skeleton-${i}`}
            className="col-span-2 p-10 space-y-6 odd:md:col-span-4 even:md:hidden even:lg:inline odd:lg:col-span-2 lg:col-span-2 odd:xl:col-span-4 xl:col-span-4"
          >
            <Skeleton width="60%" />
            <div className="space-y-3">
              <Skeleton />
              <Skeleton width="80%" />
              <Skeleton />
            </div>
            <div>
              <Skeleton width="40%" />
            </div>
          </div>
        ))}
        <div className="flex justify-center h-full space-x-2 col-span-full md:hidden">
          <Skeleton width={10} height={10} />
          <Skeleton width={10} height={10} />
        </div>
      </Container>
    );
  }

  return (
    <div className={clsx("py-4", className)} style={style}>
      <TrustPilotCarousel
        reviews={data.reviews}
        title={value.title}
        language={language}
      />
    </div>
  );
};
