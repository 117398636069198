import { SanityDocument, groq } from "next-sanity";
import { useLiveQuery } from "next-sanity/preview";
import type { PortableTextBlock } from "@portabletext/types";

import { getClient } from "../utils";

export interface ILoyaltyBenefits extends SanityDocument {
  _type: "loyaltyMemberBenefits";
  title: string;
  body: PortableTextBlock[];
}

export const loyaltyBenefitsQuery = groq`*[_type == "loyaltyMemberBenefits"][0]`;

export const useLoyaltyBenefitsData = (data?: ILoyaltyBenefits) => {
  return useLiveQuery(data || null, loyaltyBenefitsQuery);
};

export const getLoyaltyBenefitsData = async (
  _: null,
  options: { preview?: boolean },
) => {
  const client = getClient({ preview: options.preview });

  return client.fetch<ILoyaltyBenefits>(loyaltyBenefitsQuery);
};
